.selection-page {
  //Vehicle Selection

  .vehicle-select {
    cursor: pointer;
    border: none;
    border-radius: 0;
  }

  .vehicle-select.selected {
    background-color: hsla(var(--tertiary-h), var(--tertiary-s), calc(var(--tertiary-l) + 4%), 1);
    border: none;
  }
  .vehicle-select-button {
    background-color: hsla(var(--tertiary-h), var(--tertiary-s), var(--tertiary-l), 1);
    border-color: hsla(var(--tertiary-h), var(--tertiary-s), var(--tertiary-l), 1);
    border-radius: 1.4rem;
    font-weight: 400;
    padding: 0.5rem 0.3rem;
    width: 100px;
    font-size: 0.75rem;
    color: white;
    margin: 0 auto;
  }

  .vehicle-select-button.selected {
    background-color: #1c69d4;
    border-color: #1c69d4;
  }

  .vehicle-select:hover .vehicle-select-button {
    background-color: hsla(var(--tertiary-h), var(--tertiary-s), calc(var(--tertiary-l) - 15%), 1);
    border-color: hsla(var(--tertiary-h), var(--tertiary-s), calc(var(--tertiary-l) - 15%), 1);
  }

  .vehicle-select.selected:hover .vehicle-select-button {
    background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
  }

  //End of Vehicle Selection
}
