/* Color Variables */
:root {
  --body-bg-h: 209.7;
  --body-bg-s: 80.5%;
  --body-bg-l: 22.2%;

  --body-text-h: 0;
  --body-text-s: 0%;
  --body-text-l: 0%;

  --navbar-bg-h: 217.5;
  --navbar-bg-s: 100%;
  --navbar-bg-l: 15.7%;

  --navbar-text-h: 0;
  --navbar-text-s: 0%;
  --navbar-text-l: 100%;

  --steps-bg-h: 0;
  --steps-bg-s: 0%;
  --steps-bg-l: 100%;

  --primary-h: 217.5;
  --primary-s: 100%;
  --primary-l: 15.7%;

  --secondary-h: 0;
  --secondary-s: 0%;
  --secondary-l: 0%;

  --tertiary-h: 0;
  --tertiary-s: 0%;
  --tertiary-l: 91.8%;

  /* Highlight Color */
  --quaternary-h: 194.8;
  --quaternary-s: 100%;
  --quaternary-l: 46.1%;

  /* Success Color */
  --quinary-h: 145.3;
  --quinary-s: 63.4%;
  --quinary-l: 41.8%;

  //--senary:#01305f;
  //--septenary:#01305f;
  //--octonary:#01305f;
  //--nonary:#01305f;
  //--denary:#01305f;
}

/* Font Variables */
@font-face {
  font-family: "main-font-regular";
  src: url("/fonts/VWHead-Bold.woff");
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "main-font-regular", Helvetica, Arial, Sans-Serif;
}

body {
  background-color: hsla(var(--body-bg-h), var(--body-bg-s), var(--body-bg-l), 1) !important;
  color: hsla(var(--body-text-h), var(--body-text-s), var(--body-text-l), 1) !important;
}

/* All Event Steps Page Variables */
.event-steps-foreground {
  background-color: hsla(var(--steps-bg-h), var(--steps-bg-s), var(--steps-bg-l), 0.8) !important;
}

.event-steps-background {
  background-color: hsla(var(--steps-bg-h), var(--steps-bg-s), var(--steps-bg-l), 0.5) !important;
}

.navbar {
  background-color: #000000;
  color: hsla(var(--navbar-text-h), var(--navbar-text-s), var(--navbar-text-l), 1) !important;
}
